import React from 'react'
import { useEffect,useState } from 'react';
import image from "../assets/images/varu-logo.svg"


const Loader = () => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
 
  return (
    <section>
    <div className="logo-fade-animation">
        <div className="logo-overlay-wrapper">
            <div className="logo-wrapper">
                {/* <img src={image} alt=""/> */}
                <span className="overlay white-overlay"></span>
            </div>
        </div>
    </div>
</section>

  )
}

export default Loader