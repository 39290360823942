import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ExperienceTabs from "./ExperienceTabs";
import ExperienceAwait from "../../components/ExperienceAwait";

import Helmet from "../../components/Helmet";
import BannerImage  from "../../components/BannerImage";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";

import { getPageData, getPageData1 } from "../../services/dataServices";

import { useQuery } from "react-query";

import { useState } from "react";
function Experiences({ isPreview, userId, sectionId }) {
  const pageCode = "VEXPERIENCES";
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1123", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
 
  const experiencesData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "MAINBANNER"
      )[0] ?? null
  );

  const { isLoading, isSuccess } = pagesSectionData1;

  // const BannerImage =
  //   experiencesData?.[0]?.section_elements?.filter(
  //     (item) => item.field_label_code === "BANNERIMAGE"
  //   )[0]?.data ?? bannerCover;

  const BannerImageAltTag =
    experiencesData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? null;

 
  const Bannerimage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.section_code === "MAINBANNER" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data ?? bannerCover;

  const experienceAwaitData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "BOUNDLESS"
      )[0] ?? null
  );
  const experienceAwaitIcon =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "ICON"
    )[0]?.data ;
  const experienceAwaitHeading =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;

  const description =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data ?? null;
  const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  const slug = pagesSectionData?.data?.data[0]?.slug;
  const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  const meta_description = pagesSectionData?.data?.data[0]?.meta_description;
  // const heading = 'Boundless Experiences Like The Sea'
  // const para ='<p>The lagoon beckons you to explore the enchanting underwater world teeming with life, where you swim with turtles and glide over corals. The calming hues of the famed Maldivian sunset tempt you to cruise across the seas. Say cheers to life with sparkling champagne toasts under the starlit sky. Experiences like these abound at Atmosphere Kanifushi. From local immersions on land to deep explorations in the sea, intimate ceremonies to shared celebrations, the possibilities are boundless, just like the sea.</p>'
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return;
  // }
  // if (isSuccess) {
  //   setIsLoadingMain(false);
  // }
  // const handleImageLoad = () => { setIsLoadingMain(false) };

  // const handleImageLoad = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   setIsLoadingMain(true);
  // }

  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };

  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return<Loader /> ;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />

      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">

          <BannerImage
              className="w-100 h-100"
              src={Bannerimage}
              title=""
              alt={BannerImageAltTag}
              isDataIsLoading={isLoading}
            />
            {/* <img
              className="w-100 h-100"
              src={BannerImage}
              onLoad={handleImageLoad}
              onError={handleError}
              title=""
              alt={BannerImageAltTag}
            /> */}
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                experiences
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>experiences</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <ExperienceAwait
        image={experienceAwaitIcon}
        showLargeheading={true}
        showHeading={false}
        heading={experienceAwaitHeading}
        para={description}
      />

      <section>
        <div className="experience-tabs-view section-padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ExperienceTabs pagesSectionData={pagesSectionData} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Experiences;
