import React from "react";
import { createContext, useState } from "react";

const MyContext = createContext();
function MyProvider({ children }) {
  const [isLoadingMain, setIsLoadingMain] = useState(true);

  return (
    <MyContext.Provider value={{ isLoadingMain, setIsLoadingMain }}>
      {children}
    </MyContext.Provider>
  );
}

export { MyContext, MyProvider };
