import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineDownload } from "react-icons/hi";
import PlanCards from "./PlanCards";
import Image from "../../components/Image";
function PlanTabData({
  tabCoverImg = "",
  tabHeading = "",
  downloadBrochureLink,
  btnTextName = "",
  planCardData = [],
  planMeinDes = "",
  tabImageAltTag=""
}) {
   
  return (
    <>
      <div className="plan-tab-container position-relative">
        <div className="plan-cover-image w-100">
          {/* <img src={tabCoverImg} alt={tabImageAltTag} title="" /> */}
          <Image className="" src={tabCoverImg} alt={tabImageAltTag} />
          <div className="tab-head-text position-absolute w-100">
            <h2 className="h1 text-uppercase georgia letter-spacing-2 text-white">
              {tabHeading}
            </h2>
            {downloadBrochureLink ? (
              <div className="plan-brochure d-flex justify-content-end">
                <Link
                  className="georgia btn theme-btn-outline download-brochure-btn btn-outline-success fw-medium text-text-capitalize border-theme"
                  to={downloadBrochureLink}
                  target="_blank"
                >
                  {btnTextName} <HiOutlineDownload className="ms-1 fs-5" />
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="plans-cards pt-4">
          <div className="container">
            <div className="row mb-4 d-none">
              <div className="col-12">
                <div className="plan-card-text">
                  <p>{planMeinDes}</p>
                </div>
              </div>
            </div>
            {/* {
                            planCardData.map(data =>
                                <PlanCards planCardData={data} planMeinDes={planMeinDes} />
                            )
                        } */}
      

            {planCardData?.map((item, index) => {
              // getting card heading
              const card1head =
                item.filter((item) => item.field_title_code === "C1HEADING")[0]
                  ?.data ?? null;

              const card2head =
                item.filter((item) => item.field_title_code === "C2HEADING")[0]
                  ?.data ?? null;
              const card3head =
                item.filter((item) => item.field_title_code === "C4HEADING")[0]
                  ?.data ?? null;

              // getting card subheading

              const card1shead =
                item.filter((item) => item.field_title_code === "C1SHEADING")[0]
                  ?.data ?? null;

              const card2shead =
                item.filter((item) => item.field_title_code === "C2SHEADING")[0]
                  ?.data ?? null;

              const card3shead =
                item.filter((item) => item.field_title_code === "C4SHEADING")[0]
                  ?.data ?? null;

              // getting description

              const card1desc =
                item.filter(
                  (item) => item.field_title_code === "C1DESCRIPTION"
                )[0]?.data ?? null;

              const card2desc =
                item.filter(
                  (item) => item.field_title_code === "C2DESCRIPTION"
                )[0]?.data ?? null;

              const card3desc =
                item.filter(
                  (item) => item.field_title_code === "C4DESCRIPTION"
                )[0]?.data ?? null;

              // getting card Tc
              const card1Tc =
                item.filter((item) => item.field_title_code === "C1TC")[0]
                  ?.data ?? null;

              const card2Tc =
                item.filter((item) => item.field_title_code === "C2TC")[0]
                  ?.data ?? null;

              const card3Tc =
                item.filter((item) => item.field_title_code === "C4TC")[0]
                  ?.data ?? null;

              const imageCard =
                item.filter((item) => item.field_title_code === "C3IMAGE")[0]
                  ?.data ?? null;
                  const imageCardAltTag =
                item.filter((item) => item.field_title_code === "C3IMAGE")[0]
                  ?.img_alt_tag ?? null;
              const card1 = [
                {
                  heading: card1head,
                  sheading: card1shead,
                  cardDesc: card1desc,
                  cardtc: card1Tc,
                },
                {
                  heading: card2head,
                  sheading: card2shead,
                  cardDesc: card2desc,
                  cardtc: card2Tc,
                },
                { image: imageCard, imgAltTag:imageCardAltTag},
                {
                  heading: card3head,
                  sheading: card3shead,
                  cardDesc: card3desc,
                  cardtc: card3Tc,
                },
              ];

              return <PlanCards key={index} planCardData={card1} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanTabData;
