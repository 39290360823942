import React, { useState } from "react";
import Slider from "react-slick";
import { Modal } from "react-bootstrap";
import Image from "./Image";

const FullViewVillaSlider = ({ gallerySlideData, show, handleClose,styleClass}) => {
  const gallerySliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };
  const settings = { ...gallerySliderSettings };

  return (
    <>
      <Modal
        className={`gallery-slide-modal ${styleClass}`}
        size="xl"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <Slider className="slick-arrow-default" {...settings}>
            {gallerySlideData?.map((slide, index) => (
              <div className="gallery-slide h-100" key={index}>
                {slide.imageUrl && (
                  // <img src={slide.imageUrl} alt='' title='' />
                  <Image className="" src={slide.imageUrl} alt={""} />
                )}
                {slide.image_path && (
                  // <img src={slide.image_path} alt="" title="" />
                  <Image className="" src={slide.image_path} alt={""} />
                )}
              </div>
            ))}
          </Slider>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FullViewVillaSlider;
