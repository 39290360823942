import React from 'react'
import Image from '../components/Image'

function VillasAmenities({amenityText, amenityIcon}) {
  return (
    <>
        <div className='villa-amenity d-flex justify-content-center flex-wrap'>
            <div className='amenity-icon d-flex align-items-center justify-content-center'>
                {/* <img src={amenityIcon} alt='' title='' /> */}
                <Image className="w-100" src={amenityIcon} alt={""} />
            </div>
            <div className='amenity-text w-100 text-center'>
                <span>{amenityText}</span>
            </div>
        </div>
    </>
  )
}

export default VillasAmenities