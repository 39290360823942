import React, { useState } from "react";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import FullViewVillaSlider from "./FullViewVillaSlider";
import Image from './Image'

function Glimpses({showGlimpsesHeader=true,image1,topRightImagealt,image2,bottomLeftImagealt,image3,bottomCenterImagealt,image4,topCenterImagealt,image5,bottomRightImagealt,image6,topLeftImagealt,slugOfGallery}) {
    const [activeCard, setActiveCard] = useState(null);
    const [show, setShow] = useState(false);
  
    const openFullView = (image) => {
      setActiveCard(image);
      setShow(true);
    };
  
    const handleClose = () => {
      setActiveCard(null);
      setShow(false);
    };
  
    const generateGallerySlideData = () => {
      // Arrange images starting from activeCard and then looping back to the beginning
      const images = [image6, image2, image4, image3, image1, image5].filter(image => image);
    
      const startIndex = images.indexOf(activeCard);
      const arrangedImages = [
        ...images.slice(startIndex), // Images from activeCard to the end
        ...images.slice(0, startIndex), // Images from the beginning to activeCard (looping back)
      ];
      return arrangedImages?.map((img) => ({ imageUrl: img }));
    };
  
  return (
    <>
        <section>
            <div className='kanifushi-glimses section-padding pt-0'>
                <div className='container'>
                    {showGlimpsesHeader?
                        (<div className='row mb-lg-5 mb-4'>
                        <div className='col-12'>
                            <div className='hotel-heading-wrap d-flex align-items-center justify-content-between px-0'>
                            <h2 className='text-uppercase georgia heading-space mb-0'>GLIMPSES</h2>
                            <Link to={`/${slugOfGallery}`} className='text-dark text-decoration-none view-all' title='Course Link'>VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-3 icon-font' /></Link>
                            </div>
                        </div>
                    </div>): ('')
                    }
              
                    <div className='row g-1'>
                        <div className='col-lg-4 glimpse-left'>
                            <div className='glimpse-img' onClick={() => openFullView(image6)}>
                                {/* <img src={image6} alt={topLeftImagealt} title='' /> */}
                                <Image className="" src={image6} alt={topLeftImagealt}  />
                            </div>
                            <div className='glimpse-img'  onClick={() => openFullView(image2)}>
                                {/* <img src={image2} alt={bottomLeftImagealt} title='' /> */}
                                <Image className="" src={image2} alt={bottomLeftImagealt}  />
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                        <div className='col-lg-4 glimpse-mid'>
                            <div className='glimpse-img' onClick={() => openFullView(image4)}>
                                {/* <img src={image4} alt={topCenterImagealt} title='' /> */}
                                <Image className="" src={image4} alt={topCenterImagealt}  />
                            </div>
                            <div className='glimpse-img' onClick={() => openFullView(image3)}>
                                {/* <img src={image3} alt={bottomCenterImagealt} title='' /> */}
                                <Image className="" src={image3} alt={bottomCenterImagealt}  />
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                        <div className='col-lg-4 glimpse-last'>
                            <div className='glimpse-img' onClick={() => openFullView(image1)}>
                                {/* <img src={image1} alt={topRightImagealt} title='' /> */}
                                <Image className="" src={image1} alt={topRightImagealt}  />
                            </div>
                            <div className='glimpse-img' onClick={() => openFullView(image5)}>
                                {/* <img src={image5} alt={bottomRightImagealt} title='' /> */}
                                <Image className="" src={image5} alt={bottomRightImagealt}  />
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                    </div>
                </div>
                {/* /container-fluid */}
            </div>
        </section>
        {activeCard && (
        <FullViewVillaSlider gallerySlideData={generateGallerySlideData()} show={show} handleClose={handleClose} styleClass={"glimpmses-full-view"} />
        )}
    </>
  )
}

export default Glimpses