import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '.././assets/images/our-sub-brands-cover.jpg'
import ExperienceAwait from '../components/ExperienceAwait';
import starPerformanceImg from '../assets/images/diamond.svg'
import SubBrandTabs from './SubBrandTabs.js'
import DiscoverItems from './DiscoverItems.js';
import discoverOne from '../assets/images/discover-one.jpg'
import discoverTwo from '../assets/images/discover-two.jpg'
import discoverThree from '../assets/images/discover-three.jpg'
import discoverIconOne from '../assets/images/spoon-fork.svg'
import { Link } from 'react-router-dom';

function OurSubBrandsPage() {
    const para = `Our hotels and resorts are unique tales, each with a distinctive character inspired by their history, culture, and natural surroundings. <br>A stay at one of our Atmosphere Hotels & Resorts properties is the beginning of your story, with each stay becoming a bookmark in it. Because a new experience always awaits.`;

    const paraOne = "Lorem Ipsum is simply dummy text of the printing and typesetting industry...";
    const cardTitleOne = "Bedroom";

  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='' alt='' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Our Sub Brands</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Our Sub Brands</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>
        <ExperienceAwait image={starPerformanceImg} para={para} heading={"Every Stay, A Bookmark in Your Story"} showHeading={false} showLearnMore={false}/>

        <section>
            <div className='sub-brands-tabs section-padding pt-0'>
                <SubBrandTabs/>
            </div>
        </section>

        <section className='d-none'>
            <div className='atmosphere-discover section-padding pt-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='dashed-heading position-relative'>
                                <h2 className="georgia text-uppercase"><span>Discover</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className='row gx-0 mt-lg-5 mt-4'>
                        <div className='col-lg-8'>
                            <div className='discover-left'>
                                <DiscoverItems cardDiscoverImage={discoverOne} coverIcon={discoverIconOne} cardTitle={cardTitleOne} cardParagraph={paraOne}/>
                                <DiscoverItems cardDiscoverImage={discoverTwo} coverIcon={discoverIconOne} cardTitle={cardTitleOne} cardParagraph={paraOne}/>
                                <DiscoverItems cardDiscoverImage={discoverThree} coverIcon={discoverIconOne} cardTitle={cardTitleOne} cardParagraph={paraOne}/>
                            </div>
                        </div>
                        {/* /col-lg-8 */}
                        <div className='col-lg-4'>
                            <div className='discover-right'>
                                <DiscoverItems cardDiscoverImage={discoverOne} coverIcon={discoverIconOne} cardTitle={cardTitleOne} cardParagraph={paraOne}/>
                                <div className='download-brochure d-flex justify-content-center align-items-center'>
                                    <Link to='/' className='text-decoration-none text-white text-center'>
                                        {/* <img src={downloadBrochure} alt='Brochure' title='Brochure' /> */}
                                        <span className='text-uppercase text-white w-100 d-flex justify-content-center mt-3'>Book Now</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-8 */}
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default OurSubBrandsPage