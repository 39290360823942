import React, { useState,useRef, useEffect } from 'react'
import Slider from 'react-slick'
import { Button } from "react-bootstrap";
import { AiOutlineArrowsAlt } from "react-icons/ai";
import FullViewVillaSlider from "../../components/FullViewVillaSlider";
import Image from '../../components/Image';
function SunsetBeachVillaSlides({images,thumbimages, beachSliderLargeSettings, beachSliderThumbSettings}) {
    const settingsLarge = {...beachSliderLargeSettings}
    const settingsThumb = {...beachSliderThumbSettings}
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const largeSliderRef = useRef(null);
    const thumbSliderRef = useRef(null);
    const [activeCard, setActiveCard] = useState(null);
    const [show, setShow] = useState(false);
    const handleShow = (index) => {
      const clickedImage = images[index];
      const reorderedImages = [clickedImage, ...images.slice(0, index), ...images.slice(index + 1)];
      setActiveCard(reorderedImages);
      setShow(true);
  };
    const handleClose = () => {
      setActiveCard(null);
      setShow(false);
    };

    useEffect(() => {
      if (largeSliderRef.current && thumbSliderRef.current) {
          largeSliderRef.current.slickGoTo(0); // Set initial slide index for large slider
          thumbSliderRef.current.slickGoTo(0); // Set initial slide index for thumbnail slider
      }
  }, []);
  return (
    <>
    {/* asNavFor={nav2} ref={(slider1) => setNav1(slider1)} */}
        <Slider className='large-slides slick-arrow-default' {...settingsLarge} asNavFor={thumbSliderRef.current} ref={largeSliderRef} >
        
        {images?.map((image,index)=>(
           <div className='position-relative'>
          <div className='beach-slide-card position-relative' key={index}>
            <div className={`card-image overflow-hidden`}>
              <Image className='w-100' src={image.imageUrl} alt={image.imgAltTag} title='' />
            </div>
        </div>
        {image.imageUrl &&
         <div className="full-slide-view alternt-full-view  position-absolute">
                    
         <Button
           onClick={() => handleShow(index)}
           className="btn btn-primary full-btn border-0 smooth text-nowrap"
         >
           <AiOutlineArrowsAlt className="me-1" /> {"FULL VIEW"}
         </Button>
       </div>
}
       </div>
        ))}
        
    </Slider>
    <FullViewVillaSlider gallerySlideData={activeCard} show={show} handleClose={handleClose} >

</FullViewVillaSlider>
{/* asNavFor={nav1} ref={(slider2) => setNav2(slider2)} */}
    <Slider className='thumb-slides thumb-slide-default' {...settingsThumb} asNavFor={largeSliderRef.current} ref={thumbSliderRef}  swipeToSlide={true} focusOnSelect={true}>        
        {thumbimages?.map((thumbimage,index)=>(
          <div key={index} className='beach-slide-card position-relative'>
            <div className={`card-image overflow-hidden`}>
              <Image className='w-100' src={thumbimage.thumbImageUrl} alt={thumbimage.imgAltTag} title='' />
            </div>
        </div>
        ))}
        
    </Slider>

    </>
  )
}

export default SunsetBeachVillaSlides