import React from 'react'
import ExploreVillasTabs from './ExploreVillasTabs'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

function ExploreVillas({villaSlug}) {
    const exploreHeading = 'EXPLORE OUR VILLAS'
  return (
    <>
        <section>
            <div className='explore-kanifushi-villas home-villas-explore'>
                <div className='container-fluid custom-container'>
                    <div className='row'>
                    <div className='hotel-heading-wrap mb-lg-5 mb-4 d-flex align-items-center justify-content-between'>
                        <h2 className='text-uppercase georgia heading-space mb-0'>{exploreHeading}</h2>
                        <Link to={`/${villaSlug}`} className='text-dark text-decoration-none view-all' title='Course Link'>VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-3 icon-font' /></Link>
                    </div>
                    </div>
                    
                    <div className='d-flex flex-wrap'>
                        <ExploreVillasTabs villaSlug={villaSlug}/>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default ExploreVillas