import React, { useState } from 'react'
import Slider from 'react-slick'
import { Button } from "react-bootstrap";
import { AiOutlineArrowsAlt } from "react-icons/ai";

import Image from "../components/Image"

import FullViewVillaSlider from './FullViewVillaSlider';
function ExploreVillaSlides({images,thumbimages,beachSliderLargeSettings,beachSliderThumbSettings}) {
    const settingsLarge = {...beachSliderLargeSettings}
    const settingsThumb = {...beachSliderThumbSettings}
   
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [activeCard, setActiveCard] = useState(null);
    const [show, setShow] = useState(false);
    const handleShow = (index) => {
      const clickedImage = images[index];
      const reorderedImages = [clickedImage, ...images.slice(0, index), ...images.slice(index + 1)];
      setActiveCard(reorderedImages);
      setShow(true);
  };
    const handleClose = () => {
      setActiveCard(null);
      setShow(false);
    };
  return (
    <>
        <Slider className='large-slides slick-arrow-default' {...settingsLarge} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
            {images.map((image,index)=>(
                 <div key={index}  className='position-relative h-100'>
                <div className='beach-slide-card position-relative'>
                    <div className={`card-image overflow-hidden`}>
                    {/* <img className='w-100' src={image.imageUrl} alt={image.imageAltTag||''} title='' /> */}
                    <Image className="w-100" src={image?.imageUrl} alt="" />
                    </div>
                </div>
                {image.imageUrl &&
                <div className="full-slide-view position-absolute">
                    
                    <Button
                      onClick={() => handleShow(index)}
                      className="btn btn-primary full-btn border-0 smooth text-nowrap"
                    >
                      <AiOutlineArrowsAlt className="me-1" /> {"FULL VIEW"}
                    </Button>
                  </div>
                }
                </div>

            ))}
        </Slider>
        <FullViewVillaSlider gallerySlideData={activeCard} show={show} handleClose={handleClose} >
      </FullViewVillaSlider>
        <Slider className='thumb-slides slick-arrow-default thumb-slide-default mt-2' {...settingsThumb} asNavFor={nav1} ref={(slider2) => setNav2(slider2)} swipeToSlide={true} focusOnSelect={true}>
            {thumbimages?.map((thumbimage,index)=>(
            <div key={index} className='beach-slide-card position-relative'>
                <div className={`card-image overflow-hidden`}>
                {/* <img className='w-100' src={thumbimage.thumbImageUrl} alt={thumbimage.imageAltTag||''} title='' /> */}
                <Image className="" src={thumbimage?.thumbImageUrl} alt="" />
                </div>
            </div>
            ))}
        </Slider>
    </>
  )
}

export default ExploreVillaSlides