import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import { Link } from "react-router-dom";
import Glimpses from "../../components/Glimpses";
import BannerImage from "../../components/BannerImage";
import { getAllVillas, getPageData1 } from "../../services/dataServices";
import { useQuery } from "react-query";
import Helmet from "../../components/Helmet";
import AllVillas from "./AllVillas";
import { useState } from "react";
import { getPageData } from "../../services/dataServices";
function OurVillas({ isPreview, userId, sectionId, slugOfGallery,slugOfOurVillas }) {
  // const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  // const AllVillas = useQuery("villalist", getAllVillas);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const pageCode = "VVILLAS";

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
  const pagessubSectionData = useQuery(["pagessubSectionData", pageCode,isPreview,userId,sectionId], () =>
    getPageData(pageCode,isPreview,userId,sectionId)
  );
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  const { isLoading, isSuccess } = pagesSectionData;

  const sectionData = pagesSectionData?.data?.sectionData;

  const bannerImageDataObjects = sectionData?.find(
    (item) =>
      item.section_code === "VILLAS" && item.field_label_code === "BANNERIMAGE"
  );
  
  // Extract psdd_data values from filtered objects
  const bannerImageDataValues = bannerImageDataObjects?.psdd_data;
  

  const SubSectionData = pagessubSectionData?.data?.data[0]?.section_details[0]?.subSectionData[0].elements;
  const bannerImage = bannerImageDataValues
    ? bannerImageDataValues
    : bannerCover;

  const Image1 = SubSectionData?.find(
    (item) => item.field_title_code === "IMAGE5"
  ).data;
  const Image2 = SubSectionData?.find(
    (item) => item.field_title_code === "IMAGE2"
  ).data;
  const Image3 = SubSectionData?.find(
    (item) => item.field_title_code === "IMAGE4"
  ).data;
  const Image4 = SubSectionData?.find(
    (item) => item.field_title_code === "IMAGE3"
  ).data;
  const Image5 = SubSectionData?.find(
    (item) => item.field_title_code === "IMAGE6"
  ).data;
  const Image6 = SubSectionData?.find(
    (item) => item.field_title_code === "IMAGE1"
  ).data;

  const bannerImageAltTag = bannerImageDataObjects?.img_alt_tag;

  const topLeftImagealt =
    SubSectionData?.find((item) => item.field_title_code === "IMAGE1")
      .img_alt_tag ?? null;

  const topRightImagealt =
    SubSectionData?.find((item) => item.field_title_code === "IMAGE5")
      .img_alt_tag ?? null;

  const bottomLeftImagealt =
    SubSectionData?.find((item) => item.field_title_code === "IMAGE2")
      .img_alt_tag ?? null;

  const bottomCenterImagealt =
    SubSectionData?.find((item) => item.field_title_code === "IMAGE4")
      .img_alt_tag ?? null;

  const topCenterImagealt =
    SubSectionData?.find((item) => item.field_title_code === "IMAGE3")
      .img_alt_tag ?? null;

  const bottomRightImagealt =
    SubSectionData?.find((item) => item.field_title_code === "IMAGE6")
      .img_alt_tag ?? null;

  let keywords = sectionData ? sectionData[0]?.meta_keyword : "";
  let slug = sectionData ? sectionData[0]?.slug : "";
  let meta_title = sectionData ? sectionData[0]?.meta_title : "";
  let meta_description = sectionData ? sectionData[0]?.meta_description : "";
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return;
  // }
  // if (isSuccess) {
  //   setIsLoadingMain(false);
  // }
  // const handleImageLoad = () => { setIsLoadingMain(false) };

  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };

  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
          <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={isLoading}
            />


            {/* <img
              
              className="w-100 h-100"
              src={bannerImage}
              onLoad={handleImageLoad}
              onError={handleError}
              title=""
              alt={bannerImageAltTag}
            /> */}
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Villas
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Villas</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <AllVillas slugOfOurVillas={slugOfOurVillas}/> 

      <section>
        <Glimpses
          image1={Image1}
          image2={Image2}
          image3={Image3}
          image4={Image4}
          image5={Image5}
          image6={Image6}
          bottomLeftImagealt={bottomLeftImagealt}
          topRightImagealt={topRightImagealt}
          bottomCenterImagealt={bottomCenterImagealt}
          topCenterImagealt={topCenterImagealt}
          bottomRightImagealt={bottomRightImagealt}
          topLeftImagealt={topLeftImagealt}
          slugOfGallery={slugOfGallery}
        />
      </section>
    </>
  );
}

export default OurVillas;
