import React from 'react'
import { Link } from 'react-router-dom'
import Image from "../components/Image"
function ThumbPressItem({thumbCardImage, thumbCardTitle, thumbCardDate, thumbPressLink,imgAltTag}) {
  return (
    <>
        <div className='thumb-press-card'>
            <div className='thumb-card-img position-relative rounded-3 overflow-hidden'>
                {/* <img className='w-100' src={thumbCardImage} alt={imgAltTag} title='' /> */}
                <Image className="w-100" src={thumbCardImage} alt={imgAltTag} />
                <span className='position-absolute end-0 top-0 rounded-3 px-2 py-1 d-none'>{thumbCardDate}</span>
            </div>
            <div className='thumb-card-title mt-md-3 mt-2'>
                <div className='thumb-press-title'>
                  <Link to={thumbPressLink} className='georgia text-decoration-none text-dark h6 text-uppercase'>{thumbCardTitle}</Link>
                </div>
                <span className='opacity-75'>{thumbCardDate}</span>
            </div>
        </div>
    </>
  )
}

export default ThumbPressItem