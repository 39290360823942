import React from "react";
import { Link } from "react-router-dom";
import { MdArrowOutward, MdNavigateNext } from "react-icons/md";
import parse from "html-react-parser";
import Image from "../../components/Image";
import Dropdown from "react-bootstrap/Dropdown";
function DiningCard({
  diningImage,
  catDining,
  diningName,
  diningTime,
  diningDes,
  diningMenuLink,
  diningLinkTarget,
  showMenu,
  learnMoreLink,
  learnMore,
  diningcards,
  endDiningTime,
  endTime = false,
  restTagline = "",
  tags,
  slug,
  diningImgAltImg,
  restaurantMenus=[]
}) {

  const menuNotFound = 'justify-content-end'
  const menuFound = 'justify-content-between'

  function truncateAtLastWord(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);
    const lastSpaceIndex = truncatedText.lastIndexOf(" ");

    return truncatedText;
  }
  


  return (
    <>
      <div className="col-lg-6">
        <div className={`dining-card`}>
         
            <div className="dining-card-header overflow-hidden position-relative">
              <Image src={diningImage} alt={diningImgAltImg} title="" />
              {catDining ? (
              <span className="dining-cat position-absolute start-0 top-0 m-3 text-white text-uppercase" style={{ backgroundColor: `${diningcards}` }}>
                <small>{catDining}</small>
              </span>
              ) : ("")}
            </div>
          

          <div className="dining-card-body border-top-0">
            <h5 className="georgia text-uppercase">{diningName}</h5>
            <div className="dining-timing">
              {tags && tags.some((tag) => tag !== null) && (
                tags.map((item, index) => (
                  <span key={index} style={{ marginRight: '8px' }}>{item}</span>
                ))
              )}
            </div>

            {/* <p>{diningDes}</p> */}
            <p>
              {restTagline?.length > 100 ? (
                <>
                  {parse(truncateAtLastWord(restTagline, 100))}

                  {" ...."}
                </>
              ) : (
                parse(restTagline === null ? "" : restTagline)
              )}
              {/* place tagline here */}
            </p>
            <div className={`d-flex flex-wrap align-items-center ${menuFound} ${menuNotFound}`}>
            {restaurantMenus?.length !==0 ?(
                <div className="d-flex align-items-center">
                {restaurantMenus?.length > 1 ? (
                  <div className="dinning-drpdwn">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="georgia btn mb-2 w-auto ms-0 ms-sm-3 theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme remove-drop"
                      >
                        Show Menu <MdNavigateNext />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {restaurantMenus?.map((data,index) => (
                          <Dropdown.Item
                            href={data.menu_pdf}
                            key={index}
                            target="_blank"
                          >
                            {data.menu_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <Link
                    className="text-uppercase fw-bold show-menu mb-2"
                    to={restaurantMenus[0]?.menu_pdf}
                    target={diningLinkTarget}
                  >
                    {showMenu} <MdArrowOutward className="ms-1" />
                  </Link>
                )}
              </div>
              ):(
                <div></div>
              )}
              <Link
                to={slug}
                className="georgia btn mb-2 w-auto ms-0 ms-sm-3 theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
              >
                {learnMore} <MdNavigateNext />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DiningCard;
