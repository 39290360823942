import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPaperPlane,
  FaEnvelope,
} from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import FooterLogo from "../assets/images/atmosphere-logo-with-circle-new.svg";
import AtmosphereCore from "../assets/images/atmosphere-core-logo.svg";
import TwitterX from "../assets/images/twitter-x.svg";
import { Link } from "react-router-dom";
import ahrLogo from "../assets/images/ahr-logo.svg";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { MdEmail } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { newsLetter, getPageData ,getPagesSlug} from "../services/dataServices";
import { FaCheckCircle } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

export const Validation = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
function Footer() {
  const initialValues = {
    email: "",
  };
  const [loading, setLoading] = useState(false);
  const [displayNewsLetter, setdisplayNewsLetter] = useState(true);
  const [response, setResponse] = useState(null);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Validation,
    onSubmit: (values, action) => {
      setLoading(true);
      setNewsLetter();
    },
  });

  const setNewsLetter = async () => {
    const data = await newsLetter(values.email);
    setResponse(data);

    setLoading(false);
    setdisplayNewsLetter(false);
  };

  const { values, handleSubmit, errors, touched, handleChange } = formik;
  const pageCode = "VCONTACTUS";
  const pagesSectionData = useQuery(["pagesSectionData", pageCode], () =>
    getPageData(pageCode)
  );

  const getAllRoutes = useQuery("getPagesSlug", getPagesSlug);

  const slugOfTermsConditon = getAllRoutes?.data?.data
  .filter(item => item.page_code === "VTERMCONDITION")[0]?.slug ?? null;

  const slugOfPrivacyPolicy = getAllRoutes?.data?.data
  .filter(item => item.page_code === "VPRIVACYPOLICY")[0]?.slug ?? null;

  const slugOfContactUs = getAllRoutes?.data?.data
    .filter(item => item.page_code === "VCONTACTUS")[0]?.slug ?? null;

    const slugOfMedia = getAllRoutes?.data?.data
    .filter(item => item.page_code === "VMEDIA")[0]?.slug ?? null;


  const manageContactUs = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "CONTACTUS"
      )[0]
  );
  const followUsData =
    manageContactUs?.[0]?.subSectionData?.filter(
      (item) => item.sub_section_title_code === "FOLLOWUS"
    )[0] ?? null;
  const contactUsHeading =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? null;
  const facebookUrl =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "FACEBOOK"
    )[0]?.data ?? null;
  const linkedInUrl =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "LINKEDIN"
    )[0]?.data ?? null;
  const instaGramUrl =
    followUsData?.elements?.filter(
      (item) => item.field_title_code === "INSTAGRAM"
    )[0]?.data ?? null;

  let contactDetail = manageContactUs?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "CONTACTDETAILS"
  );
  const phoneNoData =
    contactDetail?.[0]?.elements?.filter(
      (item) => item.field_title_code === "PHONE"
    )[0]?.data ?? null;

  const emailIdData =
    contactDetail?.[0]?.elements?.filter(
      (item) => item.field_title_code === "EMAIL"
    )[0]?.data ?? null;

             
  return (
    <>
      <footer>
        <div className="footer-newsletter py-4">
          <div className="container">
            <div className="row my-2">
              <div className="col-12">
                <div className="d-flex align-items-center flex-md-nowrap flex-wrap justify-content-center">
                  <h6 className="text-uppercase georgia m-0 text-white">
                    SIGN UP FOR OUR NEWSLETTER
                  </h6>
                  <form action="#" method="get">
                    <div className="position-relative d-flex">
                      {displayNewsLetter ? (
                        <>
                          <Form.Control
                            type="email"
                            onChange={handleChange}
                            name="email"
                            value={values.email}
                            className="bg-transparent text-white shadow-none"
                            placeholder="Enter your email address..."
                          />

                          <Button
                            onClick={handleSubmit}
                            className="d-flex justify-content-center align-items-center text-uppercase"
                            id="button-addon2"
                          >
                            <FaPaperPlane className="icon-font" />{" "}
                            {loading ? (
                              <div className="spinner-border" role="status"></div>
                            ) : (
                              "Send"
                            )}
                          </Button>
                          {errors.email && touched.email ? (
                            <p className="form-error text-danger">
                              {errors.email}
                            </p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <p className="thank-message m-0 text-white text-capitalize">
                          <span>
                            Thank you for subscribing to our newsletter.
                          </span>
                        </p>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /footer-newsletter */}
        <div className="mein-footer">
          <div className="footer-wrap">
            <div className="row align-items-center">
              <div className="col-xxl-4 col-xl-3">
                <div className="footer-logo">
                  <Image src={ahrLogo} alt="" title="" />
                </div>
              </div>
              {/* /col-lg-3 */}
              <div className="col-xxl-4 col-xl-4 col-md-6">
                <div className="footer-col d-flex justify-content-center no-border mt-2 mt-md-0 mb-3 mb-md-0">
                  <div className="footer-links text-center">
                    {/* <Link to="https://www.atmosphere-kanifushi.com/privacy-policy/?_gl=1*1uubhgo*_ga*MTMzMjcwOTkwNS4xNjk4OTE2ODU2*_ga_5D7JCP2H7F*MTcwMDU1ODI0NS42LjAuMTcwMDU1ODI0NS42MC4wLjA" target='_blank' onClick={()=>window.scrollTo(0,0)}><span>Terms of Use</span></Link> */}
                    <Link to={`${slugOfTermsConditon}`}>
                      <span>Terms of Use</span>
                    </Link>
                    <Link to={`${slugOfPrivacyPolicy}`}>
                      <span>Privacy Policy</span>
                    </Link>

                    {/* <Link to="https://www.atmosphere-kanifushi.com/privacy-policy/?_gl=1*1uubhgo*_ga*MTMzMjcwOTkwNS4xNjk4OTE2ODU2*_ga_5D7JCP2H7F*MTcwMDU1ODI0NS42LjAuMTcwMDU1ODI0NS42MC4wLjA" target='_blank' onClick={()=>window.scrollTo(0,0)}><span>Privacy Policy</span></Link> */}
                    {/* <Link to="/coming-soon" onClick={()=>window.scrollTo(0,0)}><span>GDPR Compliance</span></Link> */}
                    <Link to={`${slugOfMedia}`}>
                      <span>Media Centre</span>
                    </Link>
                    <Link to={`/${slugOfContactUs}`}>
                      <span>Contact</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* /col-lg-3 */}
              <div className="col-xxl-4 col-xl-5 col-md-6">
                <div className="footer-col d-flex justify-content-end">
                  <div className="location d-flex align-items-center">
                    <div className="phone-footer text-center email-footer">
                      <span className="icon-phone">
                        <FaPhoneAlt className="icon-font" />
                      </span>
                      <a href={`tel:${phoneNoData}`}>
                        {phoneNoData && phoneNoData}
                      </a>
                    </div>
                    <div className="phone-footer ms-lg-5 ms-4 text-center email-footer">
                      <span className="icon-phone">
                        <FaEnvelope className="icon-font" />
                      </span>
                      <Link to={`mailto:${emailIdData}`}>{emailIdData && emailIdData}</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* /col-lg-3 */}
            </div>
          </div>
          {/* /footer-wrap */}
          <div className="bottom-footer d-flex  justify-content-between align-items-center">
            <div className="copyright">
              <span>
                &copy; Atmosphere Hotels & Resorts 2024. All Rights Reserved.{" "}
              </span>
              Powered by
              <a className="text-underline text-white" href="https://www.digitechsoft.com/" target="_blank"> Digitech.</a>
              {/* <span>Powered by <Link to='https://www.digitechsoft.com/' target='_blank' className='text-decoration-none'>Digitech</Link>.</span> */}
            </div>
            <div className="footer-social">
              {/* <Link to="https://web.facebook.com/atmospherekanifushi/" target='_blank'><FaFacebookF className='icon-font' /></Link> */}
              <Link to={facebookUrl && facebookUrl} target="_blank">
                <FaFacebookF className="icon-font" />
              </Link>
              <Link className="d-none" href="/">
                <img src={TwitterX} alt="" title="" />
              </Link>

              <Link
                //  to="https://www.linkedin.com/company/atmospherecore/about/"
                to={linkedInUrl && linkedInUrl}
                target="_blank"
              >
                <FaLinkedinIn className="icon-font" />
              </Link>
              <Link
                to={instaGramUrl && instaGramUrl}
                // to="https://www.instagram.com/atmospherekanifushi/"
                target="_blank"
              >
                <FaInstagram className="icon-font" />
              </Link>
              {/* <a href='https://www.kayak.co.uk/Reethirah-Hotels-VARU-by-Atmosphere.5326169.ksp' target='_blank'><img height='15' src='https://content.r9cdn.net/seo-res/badges/v5/LIGHT_SMALL_TRAVEL_AWARDS.png'/></a> */}
            </div>
            <div className="atmosphere-core-logo d-flex align-items-center">
              {/* <Link
                className="me-4"
                to="https://www.atmospherecore.com/"
                target="_blank"
              >
                <Image src={ahrLogo} alt="" title="" />
              </Link> */}
              <Link to="https://www.atmospherecore.com/" target="_blank">
                <Image src={AtmosphereCore} alt="" title="" />
              </Link>
            </div>
          </div>
        </div>
        {/* /main-footer */}
        <ScrollToTop smooth /> {/* Render ScrollToTop component here */}
      </footer>
    </>
  );
}

export default Footer;
