import React from "react";
import Image from "../../components/Image";

function DiningFeatures({
  diningFeatureImg,
  diningFeatureHeading,
  diningFeaturepara,
  pointlist,
  diningAltImgTag,
}) {
  //   console.log("point list is : ", pointlist);
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="dining-feature-img overflow-hidden">
            <Image className="w-100" src={diningFeatureImg} alt={diningAltImgTag} title="" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="dining-feature-text">
            <h3 className="georgia text-uppercase letter-spacing-2">
              {diningFeatureHeading}
            </h3>

            <div className="feature-para">
              <p>{diningFeaturepara}</p>
            </div>
            <div className="feature-point-list mt-lg-5 mt-4">
              <ul className="list-unstyled m-0 p-0">
                {/* {pointlist.map((list) => (
                  <li key={list.pointID}>
                    <span>{list.pointName}</span>
                  </li>
                ))} */}

                {pointlist.map((list, index) => (
                  <li key={index}>
                    <span>{list}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* /row */}
    </>
  );
}

export default DiningFeatures;
