import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Home } from "../pages/home/Home";
import OurSubBrandsPage from "../pages/OurSubBrandsPage";
import ContactUs from "../pages/contact/ContactUs";
import SustainabilityPage from "../pages/theSustainability/SustainabilityPage";
import Offers from "../pages/Offers/Offers";
import OfferDetail from "../pages/Offers/OfferDetail";
import Media from "../pages/mediaCenter/Media";
import CoomingSoon from "../pages/CoomingSoon";
import VaruBookDIrect from "../pages/VaruBookDIrect";
import OurVillas from "../pages/theVillas/OurVillas";

import DiningList from "../pages/theDining/DiningList";

import AkiraSpa from "../pages/akiraSpa/AkiraSpa";
import Experiences from "../pages/theExperience/Experiences";
import OurGallery from "../pages/theGallery/OurGallery";
import OurPlans from "../pages/thePlan/OurPlans";
import PressReleaseDetails from "../pages/mediaCenter/PressReleaseDetails";

import DiningDetails from "../pages/theDining/DiningDetails";
import VillaDetails from "../pages/theVillas/VillaDetails";
import TermsConditions from "../pages/policy/TermsConditions";
import PrivacyPolicy from "../pages/policy/PrivacyPolicy";

import PageNotFound from "../components/PageNotFound";

import { useQuery } from "react-query";
import { getPagesSlug } from "../services/dataServices";

const queryString = window.location.search; // Create a URLSearchParams object to parse the query string
const urlParams = new URLSearchParams(queryString); // Get values from the query string
const isPreview = urlParams.get("ispreview");
const userId = urlParams.get("uid");
const sectionId = urlParams.get("sid");

function AllRoutes({ errorPage, setErrorPage }) {
  const getAllRoutes = useQuery("getPagesSlug", getPagesSlug);
  const { isSuccess } = getAllRoutes;

  const slugOfKHome =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VHOME")[0]
      ?.slug ?? null;

  const slugOfPlan =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VPLAN")[0]
      ?.slug ?? null;

  const slugOfDining =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VDINING")[0]
      ?.slug ?? null;

  const slugOfAkiriSpa =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VAKIRASPA")[0]
      ?.slug ?? null;

  const slugOfContactUs =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "VCONTACTUS"
    )[0]?.slug ?? null;

  const slugOfSustainbility =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "VSUSTAINABILITY"
    )[0]?.slug ?? null;

  const slugOfOffers =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VOFFERS")[0]
      ?.slug ?? null;

  const slugOfExperience =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "VEXPERIENCES"
    )[0]?.slug ?? null;

  const slugOfGallery =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VGALLERY")[0]
      ?.slug ?? null;

  const slugOfMedia =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VMEDIA")[0]
      ?.slug ?? null;

  const slugOfOurVillas =
    getAllRoutes?.data?.data.filter((item) => item.page_code === "VVILLAS")[0]
      ?.slug ?? null;

  const slugOfTermsConditon =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "VTERMCONDITION"
    )[0]?.slug ?? null;

  const slugOfPrivacyPolicy =
    getAllRoutes?.data?.data.filter(
      (item) => item.page_code === "VPRIVACYPOLICY"
    )[0]?.slug ?? null;

  return (
    <>
      <Routes>
        <Route
          path={"/"}
          element={
            <Home
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              villaSlug={slugOfOurVillas}
              planSlug={slugOfPlan}
              offersSlug={slugOfOffers}
              sustainibilitySlug={slugOfSustainbility}
              slugOfGallery={slugOfGallery}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfOurVillas}`}
          element={
            <OurVillas
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfGallery={slugOfGallery}
              slugOfOurVillas={slugOfOurVillas}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfPlan}`}
          element={
            <OurPlans
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfDining}`}
          element={
            <DiningList
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfGallery={slugOfGallery}
              slugOfDining={slugOfDining}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfAkiriSpa}`}
          element={
            <AkiraSpa
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfGallery={slugOfGallery}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfContactUs}`}
          element={
            <ContactUs
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfSustainbility}`}
          element={
            <SustainabilityPage
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfOffers}`}
          element={
            <Offers
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfOffers={slugOfOffers}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfExperience}`}
          element={
            <Experiences
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfGallery}`}
          element={
            <OurGallery
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfGallery={slugOfGallery}
            />
          }
        ></Route>
        <Route
          path={`/${slugOfMedia}`}
          element={
            <Media
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
              slugOfMedia={slugOfMedia}
            />
          }
        ></Route>

        <Route
          path={`${slugOfTermsConditon}`}
          element={
            <TermsConditions
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>
        <Route
          path={`${slugOfPrivacyPolicy}`}
          element={
            <PrivacyPolicy
              isPreview={isPreview}
              userId={userId}
              sectionId={sectionId}
            />
          }
        ></Route>

        <Route path="/our-brands-page" element={<OurSubBrandsPage />}></Route>

        {/* <Route path="/offer-details/:offerId" element={<OfferDetail />}></Route> */}

        <Route path="/offer-details" element={<OfferDetail />}></Route>
        <Route
          
          path={`/${slugOfOffers}/:offerId`}
          element={
            <OfferDetail
              slugOfOffers={slugOfOffers}
              setErrorPage={setErrorPage}
            />
          }
        ></Route>
        <Route
         
          path={`/${slugOfDining}/:restaurantId`}
          element={
            <DiningDetails
              slugOfDining={slugOfDining}
              setErrorPage={setErrorPage}
            />
          }
        ></Route>
        <Route
          // path="/press-release-details/:websitePressReleaseId"
          path={`/${slugOfMedia}/:websitePressReleaseId`}
          element={
            <PressReleaseDetails
              slugOfMedia={slugOfMedia}
              setErrorPage={setErrorPage}
            />
          }
        ></Route>
        <Route
       
          path={`${slugOfOurVillas}/:propertyId`}
          element={
            <VillaDetails
              slugOfOurVillas={slugOfOurVillas}
              setErrorPage={setErrorPage}
            />
          }
        ></Route>

        {/* <Route path="/varu-book-direct" element={<VaruBookDIrect />}></Route>

        <Route path="/coming-soon" element={<CoomingSoon />}></Route> */}

        {/* dummy routes */}
        {/* {slugOfOurVillas && (
          <Route
            path="/villas_suites"
            element={<Navigate to={`/${slugOfOurVillas}`} />}
          />
        )}
        {slugOfPlan && (
          <Route
            path="/varu-plan"
            element={<Navigate to={`/${slugOfPlan}`} />}
          />
        )}
        {slugOfDining && (
          <Route
            path="/dining"
            element={<Navigate to={`/${slugOfDining}`} />}
          />
        )}
        {slugOfAkiriSpa && (
          <Route
            path="/experience/spa"
            element={<Navigate to={`/${slugOfAkiriSpa}`} />}
          />
        )}
        {slugOfGallery && (
          <Route
            path="/media-gallery"
            element={<Navigate to={`/${slugOfGallery}`} />}
          />
        )}
        {slugOfExperience && (
          <Route
            path="/experience/diving"
            element={<Navigate to={`/${slugOfExperience}`} />}
          />
        )}
        {slugOfExperience && (
          <Route
            path="/experience/excursions"
            element={<Navigate to={`/${slugOfExperience}`} />}
          />
        )} */}

        {isSuccess ? (
          <Route
            path="/*"
            element={
              <PageNotFound errorPage={errorPage} setErrorPage={setErrorPage} />
            }
          ></Route>
        ) : (
          ""
        )}
      </Routes>
    </>
  );
}

export default AllRoutes;
