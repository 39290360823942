import React from 'react'
import parse from 'html-react-parser'

import AwardCard from './AwardCard'
import awardOne from '../../assets/images/award-one.png'
import awardTwo from '../../assets/images/award-two.png'
import awardThree from '../../assets/images/award-three.png'
import awardFour from '../../assets/images/award-four.png'
import awardFive from '../../assets/images/award-five.png'
import awardSix from '../../assets/images/award-six.png'
import awardSeven from '../../assets/images/award-seven.png'
import awardEight from '../../assets/images/award-eight.png'
import awardNine from '../../assets/images/award-nine.png'
import awardTen from '../../assets/images/award-ten.png'
import awardEleven from '../../assets/images/award-eleven.png'
import awardTwelve from '../../assets/images/award-twelve.png'
import awardThirteen from '../../assets/images/award-thirteen.png'
import awardFourteen from '../../assets/images/award-fourteen.png'
import awardFifteen from '../../assets/images/award-fifteen.png'
import awardSixteen from '../../assets/images/award-sixteen.png'
import awardSeventeen from '../../assets/images/award-seventeen.png'
import awardEighteen from '../../assets/images/award-eighteen.png'
import awardNineteen from '../../assets/images/award-nineteen.png'
import awardTwenty from '../../assets/images/award-twenty.png'
import awardTwentyOne from '../../assets/images/award-twenty-one.png'
import awardTwentyTwo from '../../assets/images/award-twenty-two.png'
import awardTwentyThree from '../../assets/images/award-twenty-three.png'
import awardTwentyFour from '../../assets/images/award-twenty-four.png'
import awardTwentyFive from '../../assets/images/award-twenty-five.png'

import awardTwentySix from '../../assets/images/award-twenty-six.png'
import awardTwentyEight from '../../assets/images/award-twenty-eight.png'

import awardLogoOne from '../../assets/images/tripadvisor.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { getAllAwards } from '../../services/dataServices'
import { useQuery } from 'react-query';
function MediaAwards({smallHeading = 'AWARDS',heading= 'AWARDS',manageMedia}) {
  const allAwards = useQuery("getAllAwards", getAllAwards);
  const awardsData=allAwards?.data?.data;
  console.log("AwardsData",awardsData);
console.log("allAwards",allAwards);
  

/*******************************MEDIA AWARDS START********************************* */
let pressReleasesAwardsData = manageMedia?.[0]?.subSectionData?.filter(
  (item) => item.sub_section_title_code === "AWARDS"
);
const awardsIcon =
  pressReleasesAwardsData?.[0]?.elements?.filter(
    (item) => item.field_title_code === "ICON"
  )[0]?.data ?? null;


const  awardsHeading =
  pressReleasesAwardsData?.[0]?.elements?.filter(
    (item) => item.field_title_code === "HEADING"
  )[0]?.data ?? null;

const  awardsDescription =
  pressReleasesAwardsData?.[0]?.elements?.filter(
    (item) => item.field_title_code === "DESCRIPTION"
  )[0]?.data ?? "";

/*******************************MEDIA AWARDS END********************************* */
  return (
    <>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                  <img src={awardsIcon} title='' alt='' />
              </div>                      
              <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{awardsHeading}</h1>
              <div className='media-para m-auto pt-3 text-center'>
                <p>
                {parse(awardsDescription)}
                </p>
              </div>
            </div>
          </div>
          <div className='awards-list mt-lg-5 mt-4'>
            <div className='row gy-4'>

              {awardsData?.map((award) => (
                <div className='col-lg-4 col-md-6' key={award.award_id}>
                  <AwardCard 
                      awardName={award.name}
                      awardee={award.awardee}   
                      year={award.year}           
                    // awardImage={award.awardImage}
                    // awardLogo={award.awardLogo}
                    // awardCompanyName={award.awardCompanyName}

                  />
                  </div>
                ))}                
              </div>
              <div className='show-more d-flex justify-content-center d-none'>
                <Link to='/' className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control'>Show More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
              </div>
            </div>
        </div>
    </>
  )
}

export default MediaAwards