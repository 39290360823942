import React from "react";
import PlanAccordion from "./PlanAccordion";
import { Accordion } from "react-bootstrap";
import Image from "./Image";
import Scrollbars from "react-custom-scrollbars";
function PlanTabItem({ accordionData, tabFeatureImage, planFaqHeading ,tabFeatureImageAltImage=''}) {
  const defaultActiveKey = accordionData
    ? accordionData.children[0]?.page_sub_section_id 
    : 0;
  return (
    <>
      <div className="explore-plan-tabs d-flex flex-wrap">
        <div className="plan-tab-feature-img">
          <div className="tab-feature-image">
          <Image className="" src={tabFeatureImage} alt={tabFeatureImageAltImage} />
            
            {/* <img
              src={tabFeatureImage}
              alt={tabFeatureImageAltImage}
              title=''
            /> */}
          </div>
        </div>
        <div className="plan-tab-faqs">
          <div className="plan-questions">
            <h3 className="georgia text-uppercase">{planFaqHeading}</h3>
            <Scrollbars
            autoHide
            autoHideTimeout={500}
            autoHideDuration={500}
            className="planscroll-custm"
              style={{ height: 385 }}
              renderTrackHorizontal={props => <div {...props} className="track-horizontal d-none"/>}
            >
            <Accordion className="pe-4" defaultActiveKey={defaultActiveKey}>
              {accordionData?.children?.map((accData) => {
                const accordionHeading =
                  accData.elements.filter(
                    (item) => item.field_title_code === "TITLE"
                  )[0]?.data ?? null;
                const accordionDescription =
                  accData.elements.filter(
                    (item) => item.field_title_code === "DESCRIPTION"
                  )[0]?.data ?? null;

                return (
                  <PlanAccordion
                    key={accData.page_sub_section_id}
                    planTabHeading={accordionHeading}
                    planEventKey={accData.page_sub_section_id}
                    planTabText={accordionDescription}
                  />
                );
              })}
            </Accordion>
            </Scrollbars>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanTabItem;
