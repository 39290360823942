import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Scrollbars from "react-custom-scrollbars";

function PlanAccordion({ planTabHeading, planEventKey, planTabText }) {
  return (
    <>
      <Accordion.Item eventKey={planEventKey}>
        <Accordion.Header>{planTabHeading}</Accordion.Header>
        <Accordion.Body>
          <p className="home-accordion-text">
            <span className="pe-3 d-flex w-100">{planTabText}</span>
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}

export default PlanAccordion;
