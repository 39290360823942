import React from 'react'
import ExperienceCard from './ExperienceCard'


function ExperienceTabItem({tabBrand,tabMainHeading,tabAboutPara,ExperienceTabItem,colorCode}) {
  
  const Colour={
    color:colorCode
  }

  return (
    <>
        <div className={`experience-tab-data ${tabBrand}`}>
            <div className='top-tab-header text-center'>
                <h3 className='text-uppercase georgia letter-spacing-2 mb-lg-4 mb-3'style={Colour}>{tabMainHeading}</h3>
                <p>{tabAboutPara}</p>
            </div>
            <ExperienceCard ExperienceTabItem={ExperienceTabItem}/>
        </div>
    </>
  )
}

export default ExperienceTabItem