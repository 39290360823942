import React from "react";
import { TabScreen, Tabs, Tab } from "react-tabs-scrollable";
import PlanTabData from "./PlanTabData";
import Image from "../../components/Image";

function PlansTabView({ pageData = [] }) {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

 
const allpageData = pageData && pageData[0]?.subSectionData;

  return (
    <>
      <Tabs
        activeTab={activeTab}
        onTabClick={onTabClick}
         plantabsdata={allpageData}
      >
        {pageData
          ?.filter((item) => item.section_code === "TABS")[0]
          ?.subSectionData.map((item, index) => {
            const icon = item.elements.filter(
              (item) => item.field_title_code === "ICON"
            )[0]?.data;
            const tabName = item.elements.filter(
              (item) => item.field_title_code === "TABNAME"
            )[0]?.data;

            return (
              <Tab
                key={index}
                className={`item ${
                  activeTab === index
                    ? "active text-uppercase d-flex align-items-center"
                    : "text-uppercase d-flex align-items-center"
                }`}
              >
                <span className="tab-feature-img d-flex rounded-circle overflow-hidden">
                  {/* <img src={icon} alt="" title="" /> */}
                  <Image className="" src={icon} alt={""} />
                </span>
                <span className="tab-name">{tabName}</span>
              </Tab>
            );
          })}
      </Tabs>

      {pageData
        ?.filter((item) => item.section_code === "TABS")[0]
        ?.subSectionData.map((item, index) => {
          const coverImage = item.elements.filter(
            (item) => item.field_title_code === "IMAGE"
          )[0]?.data;
          const tabImageAltTag = item.elements.filter(
            (item) => item.field_title_code === "IMAGE"
          )[0]?.img_alt_tag;
          const brochureLink = item.elements.filter(
            (item) => item.field_title_code === "BROCHURE"
          )[0]?.data;

          const tabHeading = item.elements.filter(
            (item) => item.field_title_code === "HEADING"
          )[0]?.data;

          const cardDat = item.children?.map((item) => item.elements);
          return (
            <TabScreen key={index} index={index} activeTab={activeTab}>
              <PlanTabData
                tabCoverImg={coverImage}
                tabHeading={tabHeading}
                downloadBrochureLink={brochureLink}
                btnTextName="Download Brochure"
                planCardData={cardDat}
                tabImageAltTag={tabImageAltTag}
                // planMeinDes={data.planMeinDes}
              />
            </TabScreen>
          );
        })}
    </>
  );
}

export default PlansTabView;
