import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight} from '@fortawesome/free-solid-svg-icons'
import offerImgOne from '../assets/images/offer-img-1.jpg'
import offerImgTwo from '../assets/images/offer-img-2.jpg'
import offerImgThree from '../assets/images/offer-img-3.jpg'
import offerImgFour from '../assets/images/offer-img-4.jpg'
import OfferSlider from './OfferSlider'
import { getFeaturedOfferData } from '../services/dataServices'
import { useQuery } from 'react-query'

function SpecialOffers({offersSlug}) {    
    const gallerySettings = {
        arrow: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
    };

    
  let allFeaturedOffer = useQuery(
    'getFeaturedOffer',
    getFeaturedOfferData
  );
    const images = [
        {imageUrl:offerImgOne, offerSmallText:'KANIFUSHI - MALDIVES', galleryHeading:"25% OFF", offerSmallHeading:"20% Discount on Booking", galleryPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply..", learnMore:'', learnMoreLink: 'learn more', link: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms', targetLink: '_blank'},

        {imageUrl:offerImgTwo,offerSmallText:'KANIFUSHI - MALDIVES', galleryHeading:"20% OFF", offerSmallHeading:"20% Discount for 4 Nights Booking", galleryPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply..", link: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms', learnMore:'', learnMoreLink: 'learn more', targetLink: '_blank'},
        
        {imageUrl:offerImgThree,offerSmallText:'VARU - MALDIVES', offerSmallHeading:'KANIFUSHI - MALDIVES',galleryHeading:"25% OFF", galleryPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply..",link: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms', learnMore:'', learnMoreLink: 'learn more', targetLink: '_blank'},

        {imageUrl:offerImgFour,offerSmallText:'VARU - MALDIVES', offerSmallHeading:'KANIFUSHI - MALDIVES',galleryHeading:"30% OFF", galleryPara:"Lorem Ipsum is simply dummy text of the printing and text typesetting industry. Lorem Ipsum is simply..", link: 'https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms', learnMore:'', learnMoreLink: 'learn more', targetLink: '_blank'}
    ]
  return (
    <section>
        <div className='special-offers section-padding'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='hotel-heading-wrap d-flex align-items-center justify-content-lg-center justify-content-between position-relative'>
                        <h2 className='text-uppercase georgia heading-space mb-0 text-center'>Our Special offers</h2>
                        <Link to={`/${offersSlug}`} className='position-absolute end-0 top-0 text-dark text-decoration-none view-all mt-xl-3 mt-2' title='Course Link'>VIEW ALL <FontAwesomeIcon icon={faAngleRight} className='ms-3 icon-font' /></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* /container-fluid */}

            <div className='special-offers-slides mt-lg-5 mt-4'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12'>
                      <OfferSlider offersSlug={offersSlug} gallerySettings={gallerySettings} images={images} data={allFeaturedOffer}/>
                    </div>
                  </div>
                </div>
            </div>

        </div>
    </section>
  )
}

export default SpecialOffers