import React from 'react'
import Image from "../components/Image"
function TabResortsView({resortImg, resortName, resortLocation}) {
  return (
    <>
        <div className='resort-item'>
            <div className='resort-pic'>
                {/* <img src={resortImg} alt='' title=''/> */}
                <Image className="" src={resortImg} alt={""} />
            </div>
            <div className='resort-name-location'>
                <h6 className='georgia'>{resortName}</h6>
                <span className='text-uppercase'>{resortLocation}</span>
            </div>
        </div>
    </>
  )
}

export default TabResortsView