import React from 'react'

function VillasSignatures({signatureText}) {
  return (
    <>
        <div className='signature d-flex align-items-center'>
            <span className='position-relative'>{signatureText}</span>
        </div>
    </>
  )
}

export default VillasSignatures