import React from "react";
import { Helmet as Hel } from "react-helmet";
import { getCanonicalUrl } from "../services/dataServices";
import { useQuery } from "react-query";
function Helmet({ title, metaDescription, slug, keywords }) {
 
const websiteUrl=useQuery(
["canonical_url","canonical_url_varu"],
()=>getCanonicalUrl("canonical_url_varu"));
const baseUrl=websiteUrl?.data?.data?.setting_value;
 const baseCanonicalUrl = baseUrl ;
  const canonicalUrl = slug ? `${baseCanonicalUrl}/${slug}` : baseCanonicalUrl;

  return (
    <div>
      <Hel>
      {
          title?<title>{title}</title>:<title>Varu By Atmosphere</title>

        }
     
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonicalUrl} />
      
      </Hel>
    </div>
  );
}



export default Helmet;
