import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function AkiraAccordion({akraEventKey,akiraAccordionTitle,akiraAccordionBody}) {
  return (
    <>
        <Accordion.Item eventKey={akraEventKey}>
            <Accordion.Header>{akiraAccordionTitle}</Accordion.Header>
            <Accordion.Body>
                <p>{akiraAccordionBody}</p>
            </Accordion.Body>
        </Accordion.Item>
    </>
  )
}

export default AkiraAccordion