import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import TabResortsView from "./TabResortsView";
import { Link } from "react-router-dom";
import DiscoverLeftItems from "./DiscoverLeftItems.js";
import DiscoverRightItems from "./DiscoverRightItems.js";
import { useState } from "react";
import Modals from "../components/Modals.js";
import Image from "../components/Image.js";
function TabItem({
  coverImage,
  tabLogo,
  brandSubTitle,
  tabPara,
  hotelImage,
  hotelLink,
  hotelName,
  hotelLocation,
  brandtabs,
  tabResorts,
  tabDiscoverLeftSideCards,
  tabDiscoverRightSideCards,
  linksData,
  visitResort,
  visitResortName,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className={`tab-top-header position-relative mt-lg-5 mt-4 ${brandtabs}`}
      >
        <div className="tab-cover">
          {/* <img className='w-100' src={coverImage} alt='' title='' /> */}
          <Image className="w-100" src={coverImage} alt={""} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tab-text-view bg-white position-relative">
                <div className="top-logo d-flex justify-content-center text-center position-relative">
                  <span className="bg-white px-4 position-relative z-1 d-flex">
                    {/* <img className='w-100' src={tabLogo} alt='' title='' /> */}
                    <Image className="w-100" src={tabLogo} alt={""} />
                  </span>
                </div>
                <div className="sub-brand-subtitle mt-xl-5 mt-4 mb-3 text-center">
                  <h4 className="text-uppercase georgia">{brandSubTitle}</h4>
                </div>
                <div className="tabpara text-center m-auto">
                  <p>{tabPara}</p>
                </div>
                {tabResorts.length !== 0 ? (
                  <div className="resorts-view-all d-flex justify-content-center mt-lg-5 mt-4 pt-xl-2">
                    {tabResorts.map((tabResort) => (
                      <TabResortsView
                        resortImg={tabResort.resortImg}
                        resortName={tabResort.resortName}
                        resortLocation={tabResort.resortLocation}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="hotel-view-tab ms-auto me-auto mt-lg-5 mt-4">
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="left-thumb-img">
                        {/* <img src={hotelImage} alt='' title='' /> */}
                        <Image className="w-100" src={hotelImage} alt={""} />
                      </div>
                      <div className="right-hotel-text">
                        <h4 className="georgia">{hotelName}</h4>
                        <p>{hotelLocation}</p>
                        <div className="visit-now">
                          <Link
                            to={visitResort}
                            target="_blank"
                            className="text-uppercase"
                          >
                            {visitResortName}{" "}
                            <FontAwesomeIcon
                              className="ms-2"
                              icon={faAngleRight}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="atmosphere-discover section-padding pb-0">
                <div className="row">
                  <div className="col-12">
                    <div className="dashed-heading position-relative">
                      <h2 className="georgia text-uppercase">
                        <span>Discover</span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row gx-0 mt-lg-5 mt-4">
                  <div className="col-lg-8">
                    <div className="discover-left">
                      {tabDiscoverLeftSideCards.map((tabDiscover) => (
                        <DiscoverLeftItems
                          discoverImage={tabDiscover.discoverImage}
                          itemsdetails={tabDiscover.itemsdetails}
                        />
                      ))}
                      {/* discover-card */}
                    </div>
                  </div>
                  {/* /col-lg-8 */}
                  <div className="col-lg-4">
                    <div className="discover-right">
                      {tabDiscoverRightSideCards.map((tabDiscover) => (
                        <DiscoverRightItems
                          discoverImage={tabDiscover.discoverImage}
                          itemsdetails={tabDiscover.itemsdetails}
                        />
                      ))}
                      <div className="download-brochure d-flex">
                        {linksData.map((downloadLink) => (
                          <div
                            key={downloadLink.id}
                            className="w-100 h-100 d-flex"
                          >
                            <Link
                              className="text-decoration-none text-white text-center w-100 d-flex align-items-center justify-content-center"
                              onClick={handleShow}
                              to={downloadLink.url}
                            >
                              <span className="text-uppercase text-white w-100 d-flex justify-content-center">
                                {downloadLink.text}
                              </span>
                            </Link>
                            <Modals show={show} handleClose={handleClose} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* atmosphere discover */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TabItem;
