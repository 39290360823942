import React from 'react'
import parse from 'html-react-parser'
import { getAllCollateral } from '../../services/dataServices'
import CollateralMenuCards from './CollateralMenuCards'
import pdfIcon from '../../assets/images/pdf.svg'
import downloadNow from '../../assets/images/download.svg'
import CollateralCards from './CollateralCards'
import varuNuMenu from '../../assets/collaterals/varu/VARU-NU-MENU-NOV-2023.pdf'
import varuKaageMenu from '../../assets/collaterals/varu/VARU-KAAGE-NOV-2023.pdf'
import varuCharcoalMenu from '../../assets/collaterals/varu/VARU-CHARCOAL-MENU-NOV-2023.pdf'
import varuCigarMenu from '../../assets/collaterals/varu/VARU-CIGAR-MENU-2023-JAN.pdf'
import atmosphereKanifushiPlan from '../../assets/collaterals/kanifushi/PIER-SIX-MENU.pdf'
import varuBrochure from '../../assets/documents/varu-brochure.pdf'
import varuRenewalOfVows from '../../assets/documents/VARU-by-Atmosphere-Renewal-of-Vows-2023.pdf'
import varuExcursionSchedule from '../../assets/documents/Varu-Excursion-Schedule.pdf'
import varuHolidayPlan from '../../assets/documents/VARU-PLAN-2022-2023.pdf'
import kanifushiPlan2023 from '../../assets/documents/Atmosphere-Kanifushi-The-Kanifushi-Plan-2023.pdf'
import { useQuery } from 'react-query';
function MediaCollaterals({smallHeading = 'COLLATERALS',heading= 'OUR COLLATERALS',manageMedia}) {
  const allCollateral = useQuery("getAllCollateral", getAllCollateral);
  const dataCollateral=allCollateral?.data?.data;


const collateralTitle=dataCollateral?.map((item)=>item)

  const collateralKanifushi = [
    {
      id: 1,
      docTypeIcon: pdfIcon,
      collateralTitle: "Nü",
      collateralPara: "Mediterranean Seafood Speciality",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuNuMenu
    },
    {
      id: 2,
      docTypeIcon: pdfIcon,
      collateralTitle: "Kaagé",
      collateralPara: "Progressive Maldivian Cuisine",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuKaageMenu
    },
    {
      id: 3,
      docTypeIcon: pdfIcon,
      collateralTitle: "Charcoal",
      collateralPara: "Beachside Succulent Grills",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuCharcoalMenu
    },
    {
      id: 4,
      docTypeIcon: pdfIcon,
      collateralTitle: "Cigar",
      collateralPara: "Sophisticated Smoke Selection",
      docDownloadIcon: downloadNow,
      downloadCollateral: varuCigarMenu
    }
  ]
  const collateralMenuVaru = [
    {
      id: 1,
      menuDocTypeIcon: pdfIcon,
      menuCollateralTitle: "Atmosphere Brochure",
      menuCollateralPara: "October - 2023",
      menuDdocDownloadIcon: downloadNow,
      menuDownloadCollateral: varuBrochure
    },
    {
      id: 2,
      menuDocTypeIcon: pdfIcon,
      menuCollateralTitle: "Atmosphere Renewal of Vows",
      menuCollateralPara: "A Romantic Beach Celebration",
      menuDdocDownloadIcon: downloadNow,
      menuDownloadCollateral: varuRenewalOfVows
    },
    {
      id: 3,
      menuDocTypeIcon: pdfIcon,
      menuCollateralTitle: "Excursion Schedule",
      menuCollateralPara: "Authentic Island Immersion",
      menuDdocDownloadIcon: downloadNow,
      menuDownloadCollateral: varuExcursionSchedule
    },
    {
      id: 4,
      menuDocTypeIcon: pdfIcon,
      menuCollateralTitle: "Varu by Atmosphere Plan",
      menuCollateralPara: "Varu Premium Holiday Plans",
      menuDdocDownloadIcon: downloadNow,
      menuDownloadCollateral: varuHolidayPlan
    }
  ]

  let collateralData = manageMedia?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "COLLATERALS"
  );
  const collateralIcon =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "ICON"
    )[0]?.data ?? null;
  

  
  const  collateralHeading =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "HEADING"
    )[0]?.data ?? null;
  
  const  collateralDescription =
    collateralData?.[0]?.elements?.filter(
      (item) => item.field_title_code === "DESCRIPTION"
    )[0]?.data ?? "";

   

  return (
    <>
        <div className='container'>
          <div className='row'>
              <div className='col-12 text-center'>
                <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                    <img src={collateralIcon} title='' alt='' />
                </div>                    
                <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{collateralHeading}</h1>
                <div className='media-para m-auto pt-3 text-center'>
                  <p>
                  {parse(collateralDescription)}

                  </p>
                </div>
              </div> 
              
              <div className='collaterals-cards mt-lg-5 mt-4'>
                <div className='row gy-4'>
                  <div className='col-12'>
                   
                  {dataCollateral?.map((item) => (
  <div className='mb-5' key={item?.head_id}>

    <h4 className='text-uppercase georgia letter-spacing-2 mb-3'> 
    {item?.collateral_name} </h4>

    <div className='row gy-4'>
      {item?.data?.map((collateralItem) => (
        <div className='col-lg-6'>
          <CollateralCards
            key={collateralItem.id}
            collateralTitle={collateralItem.title} 
            collateralPara={collateralItem.description} 
          docTypeIcon={pdfIcon}
          docDownloadIcon={downloadNow}
            downloadCollateral={collateralItem.pdf_url}
          />
      </div>
      ))}
  </div>
  </div>
))}
 </div>

                     
                  
                </div>
              </div>
         

            </div>
        </div>
    </>
  )
}

export default MediaCollaterals